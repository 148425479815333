import * as React from 'react';
import Service from './Card';
import devImage from '../images/pc.svg';
import ntwImage from '../images/ntw.svg';
import cloudImage from '../images/cloud.svg';


function Services() {

    const services = [
        { title: 'Softwere Development', paragraph: 'We develop software for your business.', img: devImage },
        { title: 'Network Security', paragraph: 'We develop software for your business.', img: ntwImage }, 
        { title: 'Cloud Hosting', paragraph: 'We develop software for your business.', img: cloudImage }
    ];

    return (
        <div class="bg-main-yellow ">
            <div class="font-[Helvetica] bg-black min-h-screen rounded-3xl">
                <h1 class="text-center text-main-yellow text-5xl lg:text-5xl pt-16 font-bold">Que ofrecemos</h1>
                <div class="flex flex-col lg:flex-row justify-center items-center pb-24">
                    {services.map((service, index) => {
                        return (
                            <Service key={index} title={service.title} paragraph={service.paragraph} img={service.img} darkTheme="true"/>
                        );
                    })}
                </div>
            </div>
        </div>

    );
}

export default Services;