import * as React from 'react';
import Card from './Card';
import networkImage from '../images/network.png';
import supportImage from '../images/support.png';
import uxImage from '../images/ux.png';

function AboutUs() {

    const services = [
        { title: 'Wide Network', paragraph: 'We develop software for your business.', img: networkImage },
        { title: 'Unmatched Support', paragraph: 'We develop software for your business.', img: supportImage },
        { title: 'Beginner-Friendly', paragraph: 'We develop software for your business.', img: uxImage }
    ];

    return (
        <div class="font-[Helvetica] bg-main-yellow min-h-screen">
            <h1 class="text-center text-black text-5xl lg:text-5xl pt-16 font-bold">Quienes somos</h1>
            <div class="flex flex-col lg:flex-row justify-center items-center pb-24">
                {services.map((service, index) => {
                    return (
                        <Card key={index} title={service.title} paragraph={service.paragraph} img={service.img} darkTheme="false"/>
                    );
                })}
            </div>
        </div>

    );
}

export default AboutUs;