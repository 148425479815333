import * as React from 'react';
import bubble from "../images/bubble.jpg";
import Logo from './Logo';
import MainButton from './MainButton';


function Main(props) {

    return (
        <div class="bg-main-yellow min-h-screen flex flex-col px-3 pt-1 py-5 font-[Helvetica] rounded-3xl rounded-b-none">
            <Logo />
            <div class="flex flex-col items-center min-h-80 relative px-10">
                <img class="rotate-90 lg:h-[500px] sm:h-[400px] h-[300px]"
                    src={bubble} alt='Bubble' />
                <h1 class="lg:text-8xl lg:ml-8 sm:text-6xl text-5xl font-bold absolute inset-y-1/4 text-center 
                           text-shadow shadow-black fadeIn">Leave the <br />
                    tech to us.
                </h1>
                <MainButton text="LEARN MORE" redirect='services' />
            </div>
        </div>
    );
}
export default Main;