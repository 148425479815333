import { createTheme } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './components/Contact';
import Main from './components/Main';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import Clients from './components/Clients';

const theme = createTheme({
  palette: {
    primary: {
      main: '#133C31',
    },
    secondary: {
      main: '#E2D0B8',
    },
  },
});

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Main />
                <div id='services'>
                  <Services />
                </div>
                <div id='aboutus'>
                  <AboutUs />
                </div>
                <div id='clients'>
                  <Clients />
                </div>
                <div id='contact'>
                  <Contact />
                </div>
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
