import React from "react";


function Clients() {
    return (
        <div class="bg-main-yellow ">
            <div class="flex flex-col lg:flex-row bg-black rounded-3xl min-h-screen font-[Helvetica]">
                <div class="items-start">
                    <h1 class="text-center text-main-yellow text-5xl lg:text-5xl pt-16 px-16 font-bold">
                        Que opinan <br></br> nuestros clientes
                    </h1>
                </div>
                <div class="flex flex-col text-start items-end text-main-yellow mt-14 mb-10 lg:ml-32 lg:mt-60 text-xl px-10">
                    <div class="flex flex-row items-center">
                        <h3 class="pr-6 py-10 text-main-orange font-bold">Osteria Pastai</h3>
                        <p class="text-sm lg:text-xl">“Lorem ipsum dolor sit amet, magna aliqua. Ut enim ad minim veniam, <br></br>
                            quis ut aliquip ex ea commodo consequat.”</p>
                    </div>
                    <div class="flex flex-row border-t-4 border-b-4 border-main-orange items-center">
                        <h3 class="pr-6 py-10 text-main-orange font-bold">Osteria Pastai</h3>
                        <p class="text-sm lg:text-xl">“Lorem ipsum dolor sit amet, magna aliqua. Ut enim ad minim veniam, <br></br>
                            quis ut aliquip ex ea commodo consequat.”</p>
                    </div>
                    <div class="flex flex-row items-center">
                        <h3 class="pr-6 py-10 text-main-orange font-bold lg:text-xl">Osteria Pastai</h3>
                        <p class="text-sm lg:text-xl">“Lorem ipsum dolor sit amet, magna aliqua. Ut enim ad minim veniam, <br></br>
                            quis ut aliquip ex ea commodo consequat.”</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Clients;