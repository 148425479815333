import React from 'react';
import Logo from './Logo';
import MainButton from './MainButton';
import svgInsta from '../images/insta.svg';
import svgFace from '../images/facebook.svg';
import svgTwit from '../images/twitter.svg';
import bubble from '../images/bubble.jpg';

function Contact() {
    return (
        <div class="bg-main-yellow min-h-screen px-12 py-4 font-[Helvetica] mb-1 rounded-3xl rounded-t-none">
            <Logo />
            <div class="flex flex-col items-center justify-center relative">
                <img class="rotate-90 lg:h-[370px] sm:h-[200px] h-[200px]"
                    src={bubble} alt='Bubble' />
                <h2 class="text-3xl lg:text-5xl text-center font-bold absolute inset-y-1/4 lg:inset-y-1/3 
                           text-shadow-sm shadow-black fadeIn">Habla con nosotros</h2>
                <p class="text-center text-sm lg:text-lg pt-2 pb-8 absolute inset-y-[40%] lg:inset-y-[45%]
                          text-shadow-sm shadow-black fadeIn">Feel free to call, email, or hit us up on our social media accounts.</p>
                <MainButton text="CONTÁCTANOS" redirect='contact' />
            </div>
            <div class="flex lg:flex-row flex-col items-center justify-center lg:mr-10">
                <div class="flex flex-col items-center mx-24 p-4">
                    <h3 class="text-xl lg:text-3xl font-bold py-2">Email</h3>
                    <p class="text-md lg:text-xl">solarkey@gmail.com</p>
                </div>
                <div class="flex flex-col items-center mx-24 p-4">
                    <h3 class="text-xl lg:text-3xl font-bold py-2">Teléfono</h3>
                    <p class="text-md lg:text-xl">+34 6001999333</p>
                </div>
                <div class="flex flex-col items-center mx-24 p-4">
                    <h3 class="text-xl lg:text-3xl font-bold py-2">Social</h3>
                    <div class="flex flex-row items-center">
                        <div class="bg-main-orange w-8 transition duration-150 hover:brightness-110 rounded-full p-1 m-1">
                            <a href='https://www.facebook.com/' target='_blank' rel='noreferrer'>
                                <img src={svgFace} alt='Facebook' />
                            </a>
                        </div>
                        <div class="bg-main-orange w-8 transition duration-150 hover:brightness-110 rounded-full p-1 m-1">
                            <a href='https://www.x.com/' target='_blank' rel='noreferrer'>
                                <img src={svgTwit} alt='X' />
                            </a>
                        </div>
                        <div class="bg-main-orange  w-8 transition duration-150 hover:brightness-110 rounded-full p-1 m-1">
                            <a href='https://www.instagram.com/' target='_blank' rel='noreferrer'>
                                <img src={svgInsta} alt='Insta' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;