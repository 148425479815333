import React from 'react';
import { Link } from 'react-scroll';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

function MainButton(props) {
    return (
        <>
            <Link
                key={props.redirect}
                to={props.redirect}
                smooth={true}
                offset={-65}
                duration={500}
            >
                <ListItemButton
                    class="text-black bg-secondary-orange font-[Helvetica] font-bold
                               transition duration-200 hover:bg-main-orange hover:scale-110 hover:brightness-110 hover:text-ellipsis
                               border-4 border-white rounded-full
                               px-10 py-3 mb-5 text-center
                               inline-flex items-center"
                    sx={{ textAlign: 'center' }}>
                    <ListItemText primaryTypographyProps={{ fontFamily: 'Helvetica', fontWeight: '1000' }} primary={props.text} />
                </ListItemButton>
            </Link>
        </>
    );
}

export default MainButton;