import logoSolarKey from "../images/logo.png";

function Logo() {
    return(
        <div class="flex flex-col items-center">
            <img class="w-20" src={logoSolarKey} alt='Logo Solar Key'/>
            <h2 class="font-bold text-xl">Solar Key Solutions</h2>
        </div>
    );
}

export default Logo;