import React from 'react';

function Card(props) {

    const colorTheme = props.darkTheme === "true" ? "border-main-yellow  hover:contrast-125 hover:shadow-white" : "border-black hover:shadow-black";
    const colorTextPrimary = props.darkTheme === "true" ? "text-main-yellow" : "text-main-orange";
    const colorTextSecondary = props.darkTheme === "true" ? "text-white" : "text-black";

    return (
        <div class=""
            className={`flex flex-col items-center mt-24 lg:mx-8 mx-10 p-12 border-4 rounded-3xl 
                    transition duration-200 hover:scale-110 hover:shadow-lg ${colorTheme}`}>
            <img class="w-48 h-48" src={props.img} alt="Restaurant" />
            <h2 class=""
                className={`text-3xl font-bold py-3 ${colorTextPrimary}`}>{props.title}</h2>
            <p class=""
                className={`text-center ${colorTextSecondary}`}>{props.paragraph}</p>
        </div>

    );
}

export default Card;